/* eslint-disable camelcase */
import { css } from '@emotion/core';

import { getVocabColors } from '@client/utils/styles';

export const $root = ({
  borderColor
}) => css`
  border-top: 1px solid ${borderColor};
  padding: 15px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  background-color: #FFFFFF;
  display: none;

  &.show {
    display: flex;
  }
`;

const sharedButtonStyles = css`
  font-weight: 500;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  border: unset;
  padding: 0;
  margin: 4px 8px 4px 0;

  &:last-child {
    margin-right: 0;
  }
`;

function gradeButton (category, { typography, colors }) {
  const vocabColors = getVocabColors(category, colors);

  return [
    typography.family.circular,
    typography.size.M,
    sharedButtonStyles,
    css`
      color: ${vocabColors.text};
      background-color: ${vocabColors.bg};

      &.active, &:active {
        color: ${vocabColors.textActive};
        background-color: ${vocabColors.bgActive};
      }
    `
  ];
}

export const $gradeQ1 = ({ typography, colors }) => {
  return gradeButton(1, { typography, colors });
};

export const $gradeQ2 = ({ typography, colors }) => {
  return gradeButton(2, { typography, colors });
};

export const $gradeQ3 = ({ typography, colors }) => {
  return gradeButton(3, { typography, colors });
};

export const $gradeQ4 = ({ typography, colors }) => {
  return gradeButton(4, { typography, colors });
};
