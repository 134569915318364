/* eslint-disable camelcase */
import { css } from '@emotion/core';

export const $menu = ({
  colors,
  backgroundColor_themePrimary,
  backgroundColor_themePrimary_hover,
  backgroundColor_themePrimary_active
}) => css`
  display: block;
  flex: 1 1 auto;
  margin-bottom: 2px;

  & > [aria-checked='false'] + [aria-checked='false']:not(:focus) {
    border-left-color: transparent;
  }

  & > button:not(:last-of-type),
  & > *:not(:last-of-type) button {
    border-radius: 3px;
  }

  & > button:not(:first-of-type),
  & > *:not(:first-of-type) button {
    border-radius: 3px;
  }

  & > [aria-checked='true'] button {
    background-color: ${backgroundColor_themePrimary};
  }

  /* stylelint-disable selector-max-compound-selectors */
  & > [aria-checked='true'] button * {
    /* stylelint-disable-line */
    color: ${colors.ui.white[500]};
  }
  /* stylelint-enable */

  & > [aria-checked='true']:hover button {
    background-color: ${backgroundColor_themePrimary_hover};
  }

  & > [aria-checked='true']:active button,
  & > [aria-checked='true']:focus button {
    background-color: ${backgroundColor_themePrimary_active};
  }
`;

export const $menuPlaceholder = css`
  visibility: hidden;
`;
