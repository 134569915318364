/* eslint-disable camelcase */
import { css } from '@emotion/core';

export const $levelMetaData = css`
  display: flex;
  flex: 1 0 auto;
`;

export const $current = () => css`
  margin: 0;
`;

export const $heading = () => css`
  font-weight: 700;
`;

export const $target = () => css`
  margin: 0 0 0 10px;
`;
