import { css } from '@emotion/core';

export const $root = ({ colors }) => css`
  align-items: center;
  background-color: ${colors.ui.greyExtraLight[100]};
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding: 5px 10px;
`;

export const $buttonWrapper = () => css`
  flex: 1 0;
  margin-left: 5px;
`;

export const $button = ({ colors }) => css`
  color: ${colors.ui.coreBlue[700]};
`;
