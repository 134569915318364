/* istanbul ignore file */
import React from 'react';

import { ApolloProvider } from '@apollo/client';
import { LocationProvider } from '@reach/router';
import * as Sentry from '@sentry/react';
import { StoreProvider } from 'easy-peasy';
import { get } from 'lodash-es';
import ReactDOM from 'react-dom';
import { HeadProvider } from 'react-head';

import { store, history } from '@client/common/store';

import { apolloClient } from './apollo-client';
import MaintenancePage from './common/components/MaintenancePage';
import App from './common/containers/App';
import { initializeDatadog } from './datadogRum';
import { preventBrowsing, sessionExpired } from './utils/authentication';
import { logError } from './utils/log';

const envName = process.env.ENVIRONMENT_NAME || 'local';

// Handle Session expiration
document.removeEventListener('click', preventBrowsing);
document.addEventListener('session-expired', sessionExpired);

async function main () {
  initializeDatadog();
  Sentry.init({
    enabled: envName !== 'local',
    dsn: process.env.CLIENT_SENTRY_KEY,
    release: process.env.GIT_TAG || process.env.GIT_SHA,
    environment: envName,
    serverName: 'alexandria-client',
    beforeSend: (event) => {
      logError(get(event, 'exception.values.0'));
      return event;
    }
  });

  if (process.env.DOWN_FOR_MAINTENANCE === 'true') {
    ReactDOM.render(
      <MaintenancePage />,
      document.getElementById('app')
    );
  } else {
    ReactDOM.render(
      <StoreProvider store={store}>
        <LocationProvider history={history}>
          <ApolloProvider client={apolloClient}>
            <HeadProvider>
              <Sentry.ErrorBoundary>
                <App />
              </Sentry.ErrorBoundary>
            </HeadProvider>
          </ApolloProvider>
        </LocationProvider>
      </StoreProvider>,
      document.getElementById('app')
    );
    // Enable hot module replacement when developing
    // note: webpack removes this in production
    module.hot.accept();
  }
}

main();
