// Hardcoded CUID ID's to match the monolith.
const SUBJECT_PRODUCT_STREAMS = {
  EssentialsNews: 'clilvwpnz00072e644z4xu7vz',
  ELA: 'ck76k7hns00023e57ir4qn94h',
  Science: 'ck76k4yfp00003e5736p1g7x6',
  SS: 'ck76k5jf100013e57sgsj30mr',
};

const LITE_STREAM = Object.freeze({
  uid: '0x2ae227380',
  slug: 'lite',
  id: 'clfugywx100073569vvgvoqx3',
  streamTitle: 'Lite',
  name: 'Lite',
  streamDescription: 'Content that belongs in the Newsela Lite product.',
  custom: false,
  exemplar: false,
  isBrandedProduct: false,
  subject: 'OTHER',
  createdAt: '2023-03-30T02:01:09.628Z',
  updatedAt: '2024-01-02T16:26:19.835Z'
});

const ARTICLE_TYPES_WITHOUT_LEXILE = [
  'ARTICLE_POEM'
];

// Subject products and lite stream have different ways of being applied to
// Content, so in order to prevent end-user confusion, we exclude them
// from appearing with other streams in certain queries and UI elements.
const EXCLUDED_STREAM_IDS = [...Object.values(SUBJECT_PRODUCT_STREAMS), LITE_STREAM.id];

// When lexile score is <-400 or cannot be calculated, we use these values for lexile and rawLexile.
const NO_LEXILE = '---L';
const NO_RAW_LEXILE = -9999;

const READING_COMPREHENSION_STREAM_TITLE = 'Reading Comprehension';

module.exports = {
  ARTICLE_TYPES_WITHOUT_LEXILE,
  EXCLUDED_STREAM_IDS,
  LITE_STREAM,
  READING_COMPREHENSION_STREAM_TITLE,
  NO_LEXILE,
  NO_RAW_LEXILE,
  SUBJECT_PRODUCT_STREAMS,
};
